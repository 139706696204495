import * as React from "react";

import { Controller } from "react-hook-form";
import makeStyles from '@mui/styles/makeStyles';

import {
  Typography,
  Grid,
  Divider,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  Checkbox,
} from "@mui/material";

import OrderSummary from "./OrderSummary";
import CreditCardDetail from "../stripe/CreditCardDetail";
import AddressDetail from "../stripe/AddressDetail";


const useStyles = makeStyles((theme) => ({
  customerInfo: {
    background: '#FFFFFF',
    borderRadius: 4,
    padding: theme.spacing(2)
  },
}));

function ReviewSteps(props) {
  const { step, order, customer, invoice, paymentError, formMethods } = props;
  const { control, formState: { errors }} = formMethods;
  const classes = useStyles();

  return (
    <>
      <div style={{display: step === 1 ? "block" : "none"}}>
        <Grid container>
          <Grid item xs={12} md={5} lg={4} sx={{ml: {md: 2, lg: 12}, mr: {md: 8}}}>
            <Typography variant="h6">Order Summary</Typography>
            <OrderSummary order={order} md={4}></OrderSummary>
          </Grid>
          <Grid item xs={12} md={4} sx={{mt:{xs: 1, md: 4}, minWidth: 250}}>
            <FormControl sx={{ mb: 1 }} fullWidth>
              <Controller
                name="first_name"
                control={control}
                rules={{ required: true }}
                render = {({ field })=> (
                  <TextField
                      {...field}
                      error={!!errors.first_name}
                      helperText={errors.first_name?.message}
                      label="First name"
                      variant="filled"
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ mb: 1 }} fullWidth>
              <Controller
                name="last_name"
                control={control}
                rules={{ required: true }}
                render = {({ field })=> (
                  <TextField
                      {...field}
                      error={!!errors.last_name}
                      helperText={errors.last_name?.message}
                      label="Last name"
                      variant="filled"
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ mb: 1 }} fullWidth>
              <Controller
                  name="organization"
                  control={control}
                  rules={{ required: true }}
                  render = {({ field })=> (
                    <TextField
                        {...field}
                        error={!!errors.organization}
                        helperText={errors.organization?.message}
                        label="Organization"
                        variant="filled"
                    />
                  )}
                />
            </FormControl>
            <FormControl sx={{ mb: 1 }} fullWidth>
              <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render = {({ field })=> (
                    <TextField
                        {...field}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        label="Email"
                        variant="filled"
                    />
                  )}
                />
            </FormControl>

            <Divider />

            <br/>

            <Grid item xs={12}>
              <FormControl fullWidth
                error={!!errors.agree_terms}
              >
                <FormControlLabel
                  control={
                    <Controller
                      name="agree_terms"
                      control={control}
                      rules={{ required: true }}
                      render = {({ field })=> (
                        <Checkbox
                            {...field}
                        />
                      )}
                    />
                  }
                  label={
                  <Typography>I agree to Impact Observatory's
                    {' '}<a href="https://www.impactobservatory.com/legal/terms-and-conditions/" target="_blank" rel="noreferrer">Terms and Conditions</a>,
                    {' '}<a href="https://www.impactobservatory.com/legal/eula/" target="_blank" rel="noreferrer">End User License Agreement</a>,
                    {' '}and <a href="https://www.impactobservatory.com/legal/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                    </Typography>}
                  />
                  {errors.agree_terms && (
                    <FormHelperText sx={{ml:4}}>{errors.agree_terms?.message}</FormHelperText>
                  )}
                </FormControl>
            </Grid>
            
            {/* {(order.cost > 0) && (
            <FormControl sx={{ mb: 1 }} fullWidth>
                <Controller
                    name="coupon_code"
                    control={control}
                    rules={{ required: true }}
                    render = {({ field })=> (
                      <TextField
                          {...field}
                          error={!!errors.coupon_code}
                          helperText={errors.coupon_code?.message}
                          label="Promo code"
                          variant="filled"
                      />
                    )}
                  />
              </FormControl>
              )} */}

              {paymentError && (
                <FormHelperText error={true} sx={{ml: 1}}>{paymentError}</FormHelperText>
              )}
          </Grid>
        </Grid>
      </div>
      <div style={{display: step === 2 ? "block" : "none"}}>
        <Grid container>
          <Grid item xs={12} md={5} lg={4} sx={{ml: {md: 2, lg: 12}, mr: {md: 8}}}>
            <Typography variant="h6">Order Summary</Typography>
            <OrderSummary order={order} invoice={invoice} md={4}></OrderSummary>
          </Grid>
          <Grid item xs={12} md={4} sx={{mt:{xs: 1, md: 4}, minWidth: 250}}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box className={classes.customerInfo}>
                  <Typography variant="body2">{customer.email}</Typography>
                  <Typography variant="body2" color="#757575">
                    {customer.first_name} {customer.last_name}
                    {customer.organization && (
                      `, ${customer.organization}`
                    )}
                  </Typography>
                </Box>
              </Grid>

            <Grid item xs={12}></Grid>
              <AddressDetail formMethods={formMethods} />

              {paymentError && (
                <FormHelperText error={true} sx={{ml: 1}}>{paymentError}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{display: step === 3 ? "block" : "none"}}>
        <Grid container>
          <Grid item xs={12} md={5} lg={4} sx={{ml: {md: 2, lg: 12}, mr: {md: 8}}}>
            <Typography variant="h6">Order Summary</Typography>
            <OrderSummary order={order} invoice={invoice} md={4}></OrderSummary>
          </Grid>
          <Grid item xs={12} md={4} sx={{mt:{xs: 1, md: 4}, minWidth: 250}}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box className={classes.customerInfo}>
                  <Typography variant="body2">{customer.email}</Typography>
                  <Typography variant="body2" color="#757575">
                    {customer.first_name} {customer.last_name}
                    {customer.organization && (
                      `, ${customer.organization}`
                    )}
                  </Typography>
                </Box>
              </Grid>

            {invoice.total > 0 && /* only show card fields if payment is required */ (<>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                      name="billing_name"
                      control={control}
                      rules={{ required: true }}
                      render = {({ field })=> (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.billing_name}
                            helperText={errors.billing_name?.message}
                            label="Cardholder name"
                            variant="filled"
                        />
                      )}
                    />
                </FormControl>
              </Grid>
            
              <CreditCardDetail paymentError={paymentError} />

              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
            )}
            
            <Grid item xs={12}>
              <FormControl fullWidth
                error={!!errors.agree_terms}
              >
                <FormControlLabel
                  control={
                    <Controller
                      name="agree_terms"
                      control={control}
                      rules={{ required: true }}
                      render = {({ field })=> (
                        <Checkbox
                            {...field}
                        />
                      )}
                    />
                  }
                  label={
                  <Typography>I agree to Impact Observatory's
                    {' '}<a href="https://www.impactobservatory.com/legal/terms-and-conditions/" target="_blank" rel="noreferrer">Terms and Conditions</a>,
                    {' '}<a href="https://www.impactobservatory.com/legal/eula/" target="_blank" rel="noreferrer">End User License Agreement</a>,
                    {' '}and <a href="https://www.impactobservatory.com/legal/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                    </Typography>}
                  />
                  {errors.agree_terms && (
                    <FormHelperText sx={{ml:4}}>{errors.agree_terms?.message}</FormHelperText>
                  )}
                </FormControl>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </div>
  </>
  )
}

export default ReviewSteps;