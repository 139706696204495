import * as React from "react";
import { navigate } from 'gatsby';
import { set } from 'lodash';

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { trackGtagEvent } from "../../utils"

import makeStyles from '@mui/styles/makeStyles';

import {
  Backdrop,
  CircularProgress,
  Typography,
  Grid,
  Container,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import { useElements, useStripe, CardNumberElement } from "@stripe/react-stripe-js";
import { useIOProducts } from "../../hooks/use-io-products";

import Feedback from './Feedback';
import OrderError from "./OrderError";
import ReviewSteps from "./ReviewSteps";
import { stripeCustomer, stripeAddress } from "../stripe/api.js"


const MOD_API_URL = process.env.GATSBY_MOD_API_URL;


const useStyles = makeStyles((theme) => ({
    buttonSticky: {
        position: "sticky",
        bottom: 10,
        right: 10,
        width: "100vw",
    },
    buttonRoot: {
        margin: "0 auto",
        [theme.breakpoints.up('md')]: {
            width: "70%"
        }
    },
    stepperRoot: {
        background: '#f5f5f5'
    },
    stepWidth: {
        margin: '0 auto',
        width: 320,
        minHeight: 200,
        [theme.breakpoints.up("sm")]: {
            width: 400,
        },
        [theme.breakpoints.up("md")]: {
            width: 600,
        },
        [theme.breakpoints.up("big")]: {
            width: 650,
        },
        [theme.breakpoints.up("lg")]: {
            width: 800,
        },
    }
}));


export default function ReviewOrder(props) {
  const [activeStep, setActiveStep] = React.useState(1);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [order, setOrder] = React.useState({});
  const [loadError, setLoadError] = React.useState(false);
  const [invoice, setInvoice] = React.useState({});
  const [paymentIntent, setPaymentIntent] = React.useState({});
  const [paymentError, setPaymentError] = React.useState();

  const stripe = useStripe();
  const elements = useElements();
  const { IO_PRODUCT_LOOKUP } = useIOProducts();
  const classes = useStyles();

  const steps = [
    "Product",
    "Customer Info",
    // "Get Map",
    // "Billing Address",
    // "Card Info",
];

  // we don't collect card info for free orders
  // check product price and also invoice total
  const product = IO_PRODUCT_LOOKUP[order.product_id] || {};
  if (product.price === "FREE" || invoice?.total === 0) {
    steps[2] = "Customer Address";
    steps[3] = "Get Map";
  }

  const validationSchema = [
    //validation for step1 (Product)
    yup.object({
      order: yup.string().required(),
    }),
    //validation for step2 (Customer)
    yup.object({
      first_name: yup.string().required("First name is required"),
      last_name: yup.string().required("Last name is required"),
      organization: yup.string().optional(),
      email: yup.string().email("Please enter a valid email address").required("Email is required"),
      agree_terms: yup.bool().oneOf([true],"You must agree to the terms and conditions to purchase")
    }),
    //validation for step3 (Billing Address)
    yup.object({
      // address validation is complicated, and we don't really need to ensure it's shippable
      // collect the bare minimum for stripe
      address: yup.object().shape({
        country: yup.string().required('Country is required').nullable(),
        line1: yup.string().optional(),
        line2: yup.string().optional(),
        city: yup.string().optional(),
        state: yup.string().optional(),
        postal_code: yup.string().required('Postcode is required'),
      }),
    }),
    // validation for step 4 (Cart)
    yup.object({
      billing_name: yup.string().optional(), // this should still be required if invoice is not free
      // card number, expiration, cvc are handled by Stripe
      agree_terms: yup.bool().oneOf([true],"You must agree to the terms and conditions to purchase")
    })
  ];
  const stepValidationSchema = validationSchema[activeStep];

  const formMethods = useForm({
    mode: "onBlur",
    resolver: yupResolver(stepValidationSchema),
    defaultValues: {
      "order": order?.id,
      "first_name": "",
      "last_name": "",
      "organization": "",
      "email": "",
      "billing_name": "",
      "coupon_code": "",
      "agree_terms": false,
    }
  });

  const { watch, formState, getValues } = formMethods; 
  const { errors } = formState;

  const CUSTOMER_FIELDS = ['first_name', 'last_name', 'organization', 'email',
    'address.country', 'address.line1', 'address.line2', 'address.city', 'address.state', 'address.postal_code']
  const customer = watch(CUSTOMER_FIELDS).reduce((acc, val, idx) => {
    set(acc, CUSTOMER_FIELDS[idx], val);
    return acc
  }, {})

  const handleNext = async () => {
    // show spinner before submitting
    if(Object.keys(errors).length === 0) {
      setIsSubmitting(true);
    } else {
      console.error(errors);
      setIsSubmitting(false);
    }

    if (activeStep === steps.length - 1) {
      // if we are on the last step, there is no next
      return true;
    } else {
      // validate each step as we go
      const isStepValid = await formMethods.trigger();

      if (!isStepValid) {
        console.error(errors)
      }

      if (activeStep === 1 && isStepValid) {
        let coupon_code = getValues('coupon_code'); 
        const stepSuccess = await createPaymentInvoice(order.id, customer, coupon_code)
        if (!stepSuccess) {
          setIsSubmitting(false);
          return false;
        }
      }

      if (activeStep === 2 && isStepValid) {
        const stepSuccess = await updatePaymentInvoice(order.id, customer)
        if (!stepSuccess) {
          setIsSubmitting(false);
          return false;
        }
      }

      if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsSubmitting(false);
      setPaymentError(null);
      return true;
    }
  };

  const handleBack = () => {
    setPaymentError();
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    // TODO, maintain state?
    navigate('/order')
  };

  const createPaymentInvoice = async (order_id, customer, coupon_code) => {
    return await fetch(`${MOD_API_URL}/order/${order_id}/create-payment-invoice`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({
        ...customer,
        coupon: coupon_code,
      })
    }).then(async(response) => {
        if(response.ok) {
          return await response.json()
        } else if (response.status >= 400) {
          const body = await response.json()
          throw new Error(body["detail"])
        }
      }).then((resultData) => {
        // update line items for discount
        setInvoice(resultData);

        trackGtagEvent('begin_checkout', {
          currency: "USD", 
          value: order.cost/100,
          coupon: coupon_code,
          payment_type: "Stripe",
          items: {
            item_id: order.product_id,
          }});
        return true;
      })
      .catch(e => {
        console.error(e)
        setPaymentError(e.message);
        return false;
      })
  }

  const updatePaymentInvoice = async (order_id, customer) => {
    return await fetch(`${MOD_API_URL}/order/${order_id}/update-invoice`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({...customer})
    }).then(async(response) => {
        if(response.ok) {
          return await response.json()
        } else if (response.status >= 400) {
          const body = await response.json()
          throw new Error(body["detail"])
        }
    }).then((resultData) => {
      trackGtagEvent('add_shipping_info', {
        currency: "USD", 
        value: order.cost/100,
        // coupon
        payment_type: "Stripe",
        items: {
          item_id: order.product_id,
        }})
      return finalizePaymentInvoice(order_id, resultData);
    })
    .catch(e => {
      console.error(e)
      setPaymentError(e.message);
      return false;
    })
  }

  const finalizePaymentInvoice = async (order_id, invoiceData) => {
    return await fetch(`${MOD_API_URL}/order/${order_id}/finalize-invoice`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST"
    }).then(async(response) => {
        if(response.ok) {
          return await response.json()
        } else if (response.status >= 400) {
          const body = await response.json()
          throw new Error(body["detail"])
        }
      }).then((resultData) => {
        // finally we have a payment intent
        if(resultData) {
          const { client_secret } = resultData;
          setPaymentIntent({clientSecret: client_secret});
        }

        // update invoice with sales tax line item
        setInvoice(invoiceData);

        trackGtagEvent('add_payment_info', {
          currency: "USD", 
          value: order.cost/100,
          // coupon
          payment_type: "Stripe",
          items: {
            item_id: order.product_id,
          }})

      return true;
    })
  }

  const submitOrPay = async (order_id) => {
    if (paymentIntent.clientSecret) {
      // setup stripe payload
      const stripePayload = {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          // pass customer name and address to billing_details
          billing_details: {
            ...stripeCustomer(customer),
            address: stripeAddress(customer.address)
          }
        },
        receipt_email: customer.email,
      }
  
      return stripe.confirmCardPayment(paymentIntent.clientSecret, stripePayload, {
        handleActions: false // this means that we need to handle the redirect ourselves
      })
    } else {
      return await fetch(`${MOD_API_URL}/order/${order_id}/submit`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({customer: customer})
      }).then(async(response) => {
        if(response.ok) {
          return await response.json()
        } else if (response.status >= 400) {
          const body = await response.json()
          throw new Error(body["detail"])
        }
      }).catch(e => {
        console.error(e)
        return {
          error: e
        }
      })
    }
  }

  const onSubmit = async () => {
    setIsSubmitting(true);
    
    await submitOrPay(order.id).then((result) => {
        setIsSubmitting(false);
        if (result.error) {
          console.error(result.error.message);
          setPaymentError(result.error.message);
        } else if(result.paymentIntent?.status === 'requires_action') {
          setPaymentError(`Unable to complete transaction with this card type. Please contact hello@impactobservatory.com to proceed with your order`);
        } else {
          // redirect to processing, with order in state
          order.email = customer.email;
          navigate('processing', {state: {order, disableScrollUpdate: true}})
        }
      });

      trackGtagEvent('purchase', {
        currency: "USD", 
        transaction_id: order.id,
        value: order.cost/100,
        // coupon
        // tax
        items: {
          item_id: order.product_id,
        }})
  }

  React.useEffect( loadOrderFromState => {
    if (window.history.state?.order) {
      setOrder(window.history.state.order);
    }
  }, [])

  React.useEffect( loadOrderFromAPI => {
    if (order.id) {
      return
    } 
    fetch(`${MOD_API_URL}/order/${props.id}/details`)
      .then(async(response) => {
        if(response.ok) {
          return await response.json()
        } else {
            throw new Error("unable to get order")
        }
      })
      .then(resultData => {
        setOrder(resultData);
      })
      .catch(e => {
        console.error(e)
        setLoadError(`Sorry, we were unable to load order #${props.id}`)
      })
  }, [props.id, order.id])

  React.useEffect( redirectFinishedOrder => {
    if (order.status === "DONE" || order.status === "RUNNING") {
      navigate('processing', {state: {order, disableScrollUpdate: true}})
    }
  }, [order])

  return (
    order.status ? (
      <Box>
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={9}>
              <Typography variant="h4" component="h2" textAlign="center">
                Complete Your Order
              </Typography>
              <Typography variant="body1" textAlign="left" sx={{mt:2}}>
                Our Land Use and Land Cover maps identify patterns and changes anywhere on land.
                To order a map, select the product that meets your needs, find your area of interest, and specify a date range.
                Maps will be delivered to your email for download within a few hours.
              </Typography>
              <Typography variant="body1" textAlign="left" sx={{mt:2}}>
                We are always working on new products at IO. <a href="https://www.impactobservatory.com/" target="_blank">Learn more</a> and inquire about our beta offerings.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Box className={classes.stepperRoot}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => {
                    return (
                      <Step key={`step-${index}`}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>

              <Grid item container xs={12}>
                <Box justifyContent={"center"} className={classes.stepWidth}>
                  <Box>
                    <ReviewSteps step={activeStep} formMethods={formMethods} order={order} invoice={invoice} customer={customer} paymentError={paymentError} />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className={classes.buttonRoot}>
                  <Grid container justifyContent={"space-between"}>
                    <Box>
                      <Button
                        size="small"
                        variant="unstyled"
                        onClick={handleReset}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Box>
                    <Box>
                      {activeStep > 1 && (
                        <Button
                          size="small"
                          onClick={handleBack}
                          disabled={isSubmitting}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={activeStep === steps.length - 1 ? formMethods.handleSubmit(onSubmit): handleNext}
                        disabled={isSubmitting}
                      >
                        {activeStep === steps.length - 1
                          ? "Get Map" : "Next"}

                        {isSubmitting && (
                          <CircularProgress size={20} sx={{ml: 1}} />
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Feedback className={classes.stepWidth} dark={true} />
    </Box>
    ) : (
      loadError ? (
        <OrderError message={loadError}/>
      ) : (
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
      )
    )
  );
}
